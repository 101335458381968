import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Logo from '../components/Logo'
import { Flex, Box } from '@rebass/grid'
import '../styles/index.css';

function Index() {
  return (
    <main>
      <Helmet>
        <title>Thyna Technologies</title>
      </Helmet>
      <Flex justifyContent='flex-end' py={4} px={2}>
        <a href="mailto:malek@thynatechnologies.com">
          Contact us
        </a>
      </Flex>
      <Flex py={4} flexWrap='wrap' alignItems='center' justifyContent='center'>
        <Flex width={[1, 400]}>
          <Logo />
        </Flex>
        <Box width={[1, 1]}>
          <Flex justifyContent='center' py={4} className='center'>
            We build software to power the best consumer services
          </Flex>
          <Flex justifyContent='center'>
            <Flex flexWrap='wrap' width={[1, 600]}>
              <Flex justifyContent='center' width={[1, 1 / 3]}>
                <a href='https://kilo.works'>
                  kilo >
                </a>
              </Flex>
              <Flex justifyContent='center' width={[1, 1 / 3]}>
                <a href='https://sbitar.org'>
                  sbitar >
                </a>
              </Flex>
              <Flex justifyContent='center' width={[1, 1 / 3]}>
                <a href='https://www.tekmanda.com'>
                  tekmanda >
                </a>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </main>
  );
}

export default Index;
